import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Image } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Images from "../../images/index";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";

import "../Response.css";
import SecurePageSection from "../../SecurePageSection";

function VidyaResponse() {
  const user = useSelector((state) => state.authentication.user);

  const [response, setResponses] = useState([]);
  const responseId = useParams();
  const ref = React.createRef();

  const getResponse = async () => {
    const res = await axios.get(
      `/api/forms/ventures/vidya-application/${responseId.id}`
    );
    setResponses(res.data);
    console.log(res.data);
  };

  useEffect(() => {
    getResponse();
  }, []);

  const navigate = useNavigate();

  const editForm = () => {
    navigate(`/form/grpagm/${response.id}/edit`);
  };

  const printDocument = () => {
    var divContents = document.getElementById("mainForm").innerHTML;
    var a = window.open("", "blank", "height=1123,width=800,scrollbars=yes,");
    a.document.write("<html>");
    a.document.write("<link>");
    a.document
      .write(`<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous"
    />`);
    a.document.write("</link>");

    a.document.write(`
    <title>Form B</title>
    `);
    a.document.write("<style>");
    a.document.write(`@media print {
      @page {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    html {
      overflow-y: auto;
    }

    .fResponseBody {
      padding: 20px 20px;
      width: 800px;
      min-height: 1123px;
      background: #fff;
    }
    
    .page{
      page-break-after: always;
    }
    
    .centerFormat {
      width: 800px;
      margin: 0 auto;
    }
    
    
    .section2, .section3{
      margin-top: 30px;
    }
    
    .section4{
      margin-top: 20px;
    }
    
    .bordered-col{
      padding: 3px 10px;
      border: 1px solid #000;
    }
    
    .bordered-mid-col{
      padding: 3px 10px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }
    
    .bordered-row{
      padding: 3px 0;
      border: 1px solid #000;
    }
    
    
    .bordered-mid-row{
      padding: 2px 0px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }
    
    .bordered-row-b{
      padding: 2px 0;
      border: 1px solid #000;
      height: 30px;
    }
    
    .bordered-col-b{
      padding: 2px 5px;
      border: 1px solid #000;
      height: 30px;
    }
    
    .blueBG {
      background: rgb(185, 213, 250);
      border-radius: 8px;
      height: 20px;
      margin-bottom: 5px;
    }
    
    
    .bannerText {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    
    .bannerText h1 {
      font-family: "Arial-Bold";
      font-size: 24px;
      font-weight: 600;
    }
    
    .bannerText h2 {
      font-family: "Arial-Bold";
      font-size: 20px;
      font-weight: 400;
    }
    
    .toptext {
      margin: 20px 0;
    }
    
    .logoImage {
      height: 80px;
      margin: 10px;
    }
    
    .bannerYellow {
      background: #face1d;
      border-top: 12px solid #3e4095;
      margin-bottom: 30px;
      -webkit-print-color-adjust: exact;
    }
    
    .Tbox {
      border: 1px solid rgb(0, 0, 0);
      padding: 11px 15px;
      margin: 20px 0;
    }
    
    .Tsingle-enter {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Tsingle-enter-label {
      font-family: "Arial";
      font-size: 12px;
      margin-top: 3px;
      margin-bottom: 0;
      line-height: 97%;
    }
    
    .Tdouble-enter {
      font-family: "Arial";
      margin-top: 11px;
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Tsign-enter {
      font-family: "Arial";
      margin-top: 30px;
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Hdouble-enter {
      font-family: "Arial";
      margin: 16px auto;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }
    
    .H2double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 16px;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }
    
    .H3double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 14px;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }
    
    .H3single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }
    
    .H4single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 12px;
      color: #000;
      font-weight: 600;
    }
    
    .Lsingle-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }
    
    .Ldouble-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 12px
    }
    
    .response {
      font-family: "Arial";
      font-size: 12px;
      font-weight: 600;
      text-decoration: underline;
    }
    
    .Tsingle {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 8px;
    }
    
    .Tdouble {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 24px;
    }`);
    a.document.write("</style>");
    a.document.write("<body>");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    setTimeout(function() {
      a.print();
    }, 1000);
  };

  return (
    <div>
      <h2 style={{ textAlign: "center" }}>Vidya Response</h2>
      <Row style={{ marginBottom: "1em", marginTop: "1em" }}>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button onClick={printDocument} style={{ margin: "0 5px" }}>
            Download Form
          </Button>
        </Col>
      </Row>

      <div id="mainForm" className="centerFormat">
        <div className="fResponseBody">
          <div className="page" style={{ overflow: "hidden" }}>
            <div className="bannerYellow">
              <Row className="T-Banner">
                <Col xs={1}>
                  <img
                    src={Images.LogoB}
                    className="logoImage"
                    alt="jsgBanner"
                  ></img>
                </Col>
                <Col xs={11} className="bannerText text-center">
                  <h1
                    style={{
                      margin: "0",
                    }}
                  >
                    JAIN SOCIAL GROUP INT. FEDERATION
                  </h1>
                  <p
                    className="Tsingle-enter"
                    style={{
                      padding: "0 20px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    4-O/P, Vijay Chambers, Opp. Dreamland Cinema, Tribhuvan
                    Road, Mumbai - 400 004. (India)
                  </p>
                  <p
                    className="Tsingle-enter"
                    style={{
                      padding: "0 20px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Tel.: 022-23870724 / 23891884 Mobile : 8169274400
                  </p>
                  <p
                    className="Tsingle-enter"
                    style={{
                      padding: "0 20px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    E-mail : office@jsgif.co.in Website : www.jsgif.co.i
                  </p>
                </Col>
              </Row>
            </div>
            <Row>
              <h2 style={{ textAlign: "center" }}>Vidya</h2>
            </Row>
            <Row>
              <h6
                className="Tsingle-enter"
                style={{ textTransform: "capitalize" }}
              >
                Region - {response.regName} | Group - {response.grpName}
              </h6>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="Tdouble-enter">
                  Student's Name: {response.sName}
                </p>
              </Col>
              <Col xs={4}>
                <p className="Tdouble-enter">
                  Mobile Number: {response.mobile}
                </p>
              </Col>
              <Col xs={4}>
                <p className="Tdouble-enter">
                  Whatsapp Number: {response.whatsapp}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="Tsingle-enter">
                  Student's Email: {response.email}
                </p>
              </Col>
              <Col xs={4}>
                <p className="Tsingle-enter">
                  Aadhar Number: {response.sAadhar}
                </p>
              </Col>
              <Col xs={4}>
                <p className="Tsingle-enter">PAN Number: {response.sPAN}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="Tsingle-enter">Student's DOB: {response.sDob}</p>
              </Col>
              <Col xs={4}>
                <p className="Tsingle-enter">
                  Marital Status: {response.sMarried}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="Tdouble-enter">
                  Spouse's Name: {response.sSpouseName}
                </p>
              </Col>
              <Col xs={4}>
                <p className="Tdouble-enter">
                  Spouse's Aadhar Number: {response.sSpouseAadhar}
                </p>
              </Col>
              <Col xs={4}>
                <p className="Tdouble-enter">
                  Spouse PAN Number: {response.sSpousePAN}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <p className="Tsingle-enter">
                  Home Address: {response.hAddress}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <p className="Tsingle-enter">
                  Office Address: {response.oAddress}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="Tdouble-enter">Father's Name: {response.FName}</p>
              </Col>
              <Col xs={4}>
                <p className="Tdouble-enter">
                  Father's Aadhar Number: {response.fAadhar}
                </p>
              </Col>
              <Col xs={4}>
                <p className="Tdouble-enter">
                  Father's PAN Number: {response.fPAN}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="Tsingle-enter">Mother's Name: {response.MName}</p>
              </Col>
              <Col xs={4}>
                <p className="Tsingle-enter">
                  Mother's Aadhar Number: {response.mAadhar}
                </p>
              </Col>
              <Col xs={4}>
                <p className="Tsingle-enter">
                  Mother's PAN Number: {response.mPAN}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <p className="Tdouble-enter">SSC Board: {response.sSSCBoard}</p>
              </Col>
              <Col xs={6}>
                <p className="Tdouble-enter">
                  SSC Passing Year: {response.sSSCYear}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <p className="Tsingle-enter">SSC Marks: {response.sSSCMarks}</p>
              </Col>
              <Col xs={6}>
                <p className="Tsingle-enter">SSC Grade: {response.sSSCGrade}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <p className="Tdouble-enter">HSC Board: {response.sHSCBoard}</p>
              </Col>
              <Col xs={6}>
                <p className="Tdouble-enter">
                  HSC Passing Year: {response.sHSCYear}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <p className="Tsingle-enter">HSC Marks: {response.sHSCMarks}</p>
              </Col>
              <Col xs={6}>
                <p className="Tsingle-enter">HSC Grade: {response.sHSCGrade}</p>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <p className="Tdouble-enter">
                  Graduation College: {response.sGradCollege}
                </p>
              </Col>
              <Col xs={6}>
                <p className="Tdouble-enter">
                  Graduation University: {response.sGradUni}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <p className="Tsingle-enter">
                  Graduation Year: {response.sGradYear}
                </p>
              </Col>
              <Col xs={6}>
                <p className="Tsingle-enter">
                  Graduation Marks: {response.sGradMarks}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <p className="Tsingle-enter">
                  Graduation Grade: {response.sGradGrade}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <p className="Tdouble-enter">
                  Post Graduation College: {response.sPostGradCollege}
                </p>
              </Col>
              <Col xs={6}>
                <p className="Tdouble-enter">
                  Post Graduation University: {response.sPostGradUni}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <p className="Tsingle-enter">
                  Post Graduation Year: {response.sPostGradYear}
                </p>
              </Col>
              <Col xs={6}>
                <p className="Tsingle-enter">
                  Post Graduation Marks: {response.sPostGradMarks}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <p className="Tsingle-enter">
                  Post Graduation PostGrade: {response.sPostGradGrade}
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={4}>
                <p className="Tdouble-enter">
                  Course Name: {response.sCourseName}
                </p>
              </Col>
              <Col xs={4}>
                <p className="Tdouble-enter">
                  Course Country: {response.sCourseCountry}
                </p>
              </Col>
              <Col xs={4}>
                <p className="Tdouble-enter">
                  Course Intake Year: {response.sCourseIntake}
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VidyaResponse;
