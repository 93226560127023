import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SecurePageSection from "../../SecurePageSection";

const VidyaForm = () => {
  const user = useSelector((state) => state.authentication.user);

  const [sName, setsName] = useState("");
  const [FName, setFName] = useState("");
  const [MName, setMName] = useState("");
  const [regName, setregName] = useState("");
  const [grpName, setgrpName] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [whatsapp, setwhatsapp] = useState("");
  const [hAddress, sethAddress] = useState("");
  const [oAddress, setoAddress] = useState("");
  const [fAadhar, setfAadhar] = useState("");
  const [fPAN, setfPAN] = useState("");
  const [mAadhar, setmAadhar] = useState("");
  const [mPAN, setmPAN] = useState("");
  const [sDob, setsDob] = useState("");
  const [sAadhar, setsAadhar] = useState("");
  const [sPAN, setsPAN] = useState("");
  const [sMarried, setsMarried] = useState("");
  const [sSpouseName, setsSpouseName] = useState("");
  const [sSpouseAadhar, setsSpouseAadhar] = useState("");
  const [sSpousePAN, setsSpousePAN] = useState("");
  const [sSSCBoard, setsSSCBoard] = useState("");
  const [sSSCYear, setsSSCYear] = useState("");
  const [sSSCMarks, setsSSCMarks] = useState("");
  const [sSSCGrade, setsSSCGrade] = useState("");
  const [sHSCBoard, setsHSCBoard] = useState("");
  const [sHSCYear, setsHSCYear] = useState("");
  const [sHSCMarks, setsHSCMarks] = useState("");
  const [sHSCGrade, setsHSCGrade] = useState("");
  const [sGradCollege, setsGradCollege] = useState("");
  const [sGradUni, setsGradUni] = useState("");
  const [sGradYear, setsGradYear] = useState("");
  const [sGradMarks, setsGradMarks] = useState("");
  const [sGradGrade, setsGradGrade] = useState("");
  const [sPostGradCollege, setsPostGradCollege] = useState("");
  const [sPostGradUni, setsPostGradUni] = useState("");
  const [sPostGradYear, setsPostGradYear] = useState("");
  const [sPostGradMarks, setsPostGradMarks] = useState("");
  const [sPostGradGrade, setsPostGradGrade] = useState("");
  const [sCourseName, setsCourseName] = useState("");
  const [sCourseCountry, setsCourseCountry] = useState("");
  const [sCourseIntake, setsCourseIntake] = useState("");
  const [emailList, setemailList] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const navigate = useNavigate();

  const [regEmails, setRegEmails] = useState("");
  const [regConvenor, setRegConvenor] = useState("");

  const fetchRegEmails = async () => {
    const chairmanResponse = await axios.get(
      `api/data/${regName}/region-ob/emails/`
    );
    const chairmanEmail = chairmanResponse.data
      .filter((item) => item.post === "chairman")
      .map((item) => item.email);
    setRegEmails(chairmanEmail);

    const convenorResponse = await axios.get(
      `api/data/vidya/region-convenor/emails/`
    );
    const convenorEmail = convenorResponse.data
      .filter(
        (item) =>
          item.post ===
            `${regName.charAt(0).toUpperCase() +
              regName.slice(1).toLowerCase()} Region Convenor` ||
          item.post ===
            `${regName.charAt(0).toUpperCase() +
              regName.slice(1).toLowerCase()} Region Coordinator`
      )
      .map((item) => item.email);
    setRegConvenor(convenorEmail);
  };

  useEffect(() => {
    fetchRegEmails();
  }, [regName]);

  async function FormSubmit(event) {
    event.preventDefault();
    setSubmitting(true);
    let formField = new FormData();

    const finalEmail =
      "president@jsgif.co.in,secretarygeneral@jsgif.co.in,office@jsgif.co.in,dj22jsgif@gmail.com,koradiadimple.jsg@gmail.com,divyadityakothari25@gmail.com" +
      regEmails +
      "," +
      regConvenor;

    if (!regName || !grpName || !sName || !FName || !email || !mobile) {
      alert(
        "Please Submit your Region Name, Group Name, Student's Name, Email ID, Mobile Phone & Father's Name, "
      );
      setSubmitting(false);
      return;
    }

    formField.append("user", user?.user_id);
    formField.append("regName", regName);

    formField.append("sName", sName);
    formField.append("FName", FName);
    formField.append("MName", MName);
    formField.append("grpName", grpName);
    formField.append("email", email);
    formField.append("mobile", mobile);
    formField.append("whatsapp", whatsapp);
    formField.append("hAddress", hAddress);
    formField.append("oAddress", oAddress);
    formField.append("fAadhar", fAadhar);
    formField.append("fPAN", fPAN);
    formField.append("mAadhar", mAadhar);
    formField.append("mPAN", mPAN);
    formField.append("sDob", sDob);
    formField.append("sAadhar", sAadhar);
    formField.append("sPAN", sPAN);
    formField.append("sMarried", sMarried);
    formField.append("sSpouseName", sSpouseName);
    formField.append("sSpouseAadhar", sSpouseAadhar);
    formField.append("sSpousePAN", sSpousePAN);
    formField.append("sSSCBoard", sSSCBoard);
    formField.append("sSSCYear", sSSCYear);
    formField.append("sSSCMarks", sSSCMarks);
    formField.append("sSSCGrade", sSSCGrade);
    formField.append("sHSCBoard", sHSCBoard);
    formField.append("sHSCYear", sHSCYear);
    formField.append("sHSCMarks", sHSCMarks);
    formField.append("sHSCGrade", sHSCGrade);
    formField.append("sGradCollege", sGradCollege);
    formField.append("sGradUni", sGradUni);
    formField.append("sGradYear", sGradYear);
    formField.append("sGradMarks", sGradMarks);
    formField.append("sGradGrade", sGradGrade);
    formField.append("sPostGradCollege", sPostGradCollege);
    formField.append("sPostGradUni", sPostGradUni);
    formField.append("sPostGradYear", sPostGradYear);
    formField.append("sPostGradMarks", sPostGradMarks);
    formField.append("sPostGradGrade", sPostGradGrade);
    formField.append("sCourseName", sCourseName);
    formField.append("sCourseCountry", sCourseCountry);
    formField.append("sCourseIntake", sCourseIntake);
    formField.append("emailList", finalEmail);

    await axios({
      method: "post",
      url: "/api/forms/ventures/vidya-applications/",
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        setSubmitting(false);
        navigate(`/vidya-application-form/response/${response.data.id}`, {
          replace: true,
        });
      })
      .catch(function(error) {
        console.log(error);
        setSubmitting(false);
      });
    setSubmitting(false);
  }

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get(`/user/region/`);
    const sortedRegions = response.data.sort((a, b) =>
      a.region.localeCompare(b.region)
    );
    setRegions(sortedRegions);
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async () => {
    const response = await axios.get(`/user/${regName}/groups/`);
    console.log(response.data);
    const sortedGroups = response.data.sort((a, b) =>
      a.group.localeCompare(b.region)
    );
    setGroups(sortedGroups);
  };
  useEffect(() => {
    fetchGroups();
  }, [regName]);

  const handleFileChange = (setState, event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Check if file size is less than 2MB
      if (selectedFile.size <= 1 * 1024 * 1024) {
        setState(selectedFile);
      } else {
        // File size exceeds 2MB, reset file input
        event.target.value = null;
        setState(null);
        // You can also provide feedback to the user, e.g., show an error message
        alert("File size must be less than 1MB.");
      }
    }
  };

  return (
    <div>
      <Container>
        <Form>
          <Row>
            <Col md={8}>
              <h2>Vidya Application Form</h2>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Region Name</Form.Label>
                <Form.Select
                  name="regName"
                  value={regName}
                  onChange={(e) => setregName(e.target.value)}
                >
                  <option>Select your Region</option>
                  {regions.map((region, index) => (
                    <option
                      key={index}
                      style={{ textTransform: "capitalize" }}
                      value={region.region.toLowerCase()}
                    >
                      {region.region.charAt(0).toUpperCase() +
                        region.region.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Group Name</Form.Label>
                <Form.Select
                  name="grpName"
                  value={grpName}
                  onChange={(e) => setgrpName(e.target.value)}
                >
                  <option>Select your Group</option>
                  {groups.map((group, index) => (
                    <option
                      key={index}
                      style={{ textTransform: "capitalize" }}
                      value={group.group.toLowerCase()}
                    >
                      {group.group.charAt(0).toUpperCase() +
                        group.group.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Student's Name</Form.Label>
                <Form.Control
                  name="sName"
                  value={sName}
                  placeholder="Student's Name"
                  onChange={(e) => setsName(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Father's Name</Form.Label>
                <Form.Control
                  name="FName"
                  value={FName}
                  placeholder="Father's Name"
                  onChange={(e) => setFName(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Mother's Name</Form.Label>
                <Form.Control
                  name="MName"
                  value={MName}
                  placeholder="Mother's Name"
                  onChange={(e) => setMName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  name="mobile"
                  value={mobile}
                  placeholder="Mobile"
                  onChange={(e) => setmobile(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Whatsapp</Form.Label>
                <Form.Control
                  name="whatsapp"
                  value={whatsapp}
                  placeholder="Whatsapp"
                  onChange={(e) => setwhatsapp(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  name="email"
                  value={email}
                  placeholder="Email Address"
                  onChange={(e) => setemail(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Home Address</Form.Label>
                <Form.Control
                  name="hAddress"
                  value={hAddress}
                  placeholder="Home Address"
                  onChange={(e) => sethAddress(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Office Address</Form.Label>
                <Form.Control
                  name="oAddress"
                  value={oAddress}
                  placeholder="Office Address"
                  onChange={(e) => setoAddress(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Father's Aadhar</Form.Label>
                <Form.Control
                  name="fAadhar"
                  value={fAadhar}
                  placeholder="Father's Aadhar"
                  onChange={(e) => setfAadhar(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Father's PAN</Form.Label>
                <Form.Control
                  name="fPAN"
                  value={fPAN}
                  placeholder="Father's PAN"
                  onChange={(e) => setfPAN(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Mother's Aadhar</Form.Label>
                <Form.Control
                  name="mAadhar"
                  value={mAadhar}
                  placeholder="Mother's Aadhar"
                  onChange={(e) => setmAadhar(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Mother's PAN</Form.Label>
                <Form.Control
                  name="mPAN"
                  value={mPAN}
                  placeholder="Mother's PAN"
                  onChange={(e) => setmPAN(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Student's Aadhar</Form.Label>
                <Form.Control
                  name="sAadhar"
                  value={sAadhar}
                  placeholder="Student's Aadhar"
                  onChange={(e) => setsAadhar(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Student's PAN</Form.Label>
                <Form.Control
                  name="sPAN"
                  value={sPAN}
                  placeholder="Student's PAN"
                  onChange={(e) => setsPAN(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Student's Dob</Form.Label>
                <Form.Control
                  type="Date"
                  name="sDob"
                  value={sDob}
                  placeholder="Student's Dob"
                  onChange={(e) => setsDob(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Student's Maritial Status</Form.Label>
                <Form.Control
                  name="sMarried"
                  value={sMarried}
                  placeholder="sMarried"
                  onChange={(e) => setsMarried(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Student's Spouse Name</Form.Label>
                <Form.Control
                  name="sSpouseName"
                  value={sSpouseName}
                  placeholder="Student's Spouse Name"
                  onChange={(e) => setsSpouseName(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse's Aadhar</Form.Label>
                <Form.Control
                  name="sSpouseAadhar"
                  value={sSpouseAadhar}
                  placeholder="Spouse's Aadhar"
                  onChange={(e) => setsSpouseAadhar(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Spouse's PAN</Form.Label>
                <Form.Control
                  name="sSpousePAN"
                  value={sSpousePAN}
                  placeholder="Spouse's PAN"
                  onChange={(e) => setsSpousePAN(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>SSC Board (Class X)</Form.Label>
                <Form.Control
                  name="sSSCBoard"
                  value={sSSCBoard}
                  placeholder="SSCBoard"
                  onChange={(e) => setsSSCBoard(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>SSC Year (Class X)</Form.Label>
                <Form.Control
                  name="sSSCYear"
                  value={sSSCYear}
                  placeholder="SSC Year"
                  onChange={(e) => setsSSCYear(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>SSC Marks (Class X)</Form.Label>
                <Form.Control
                  name="sSSCMarks"
                  value={sSSCMarks}
                  placeholder="SSC Marks"
                  onChange={(e) => setsSSCMarks(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>SSC Grade (Class X)</Form.Label>
                <Form.Control
                  name="sSSCGrade"
                  value={sSSCGrade}
                  placeholder="sSSCGrade"
                  onChange={(e) => setsSSCGrade(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>HSC Board (Class XII)</Form.Label>
                <Form.Control
                  name="sHSCBoard"
                  value={sHSCBoard}
                  placeholder="HSCBoard"
                  onChange={(e) => setsHSCBoard(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>HSC Year (Class XII)</Form.Label>
                <Form.Control
                  name="sHSCYear"
                  value={sHSCYear}
                  placeholder="HSC Year"
                  onChange={(e) => setsHSCYear(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>HSC Marks (Class XII)</Form.Label>
                <Form.Control
                  name="sHSCMarks"
                  value={sHSCMarks}
                  placeholder="HSC Marks"
                  onChange={(e) => setsHSCMarks(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>HSC Grade (Class XII)</Form.Label>
                <Form.Control
                  name="sHSCGrade"
                  value={sHSCGrade}
                  placeholder="sHSCGrade"
                  onChange={(e) => setsHSCGrade(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Student's Graduation College</Form.Label>
                <Form.Control
                  name="sGradCollege"
                  value={sGradCollege}
                  placeholder="Student's GradCollege"
                  onChange={(e) => setsGradCollege(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Student's Graduation University</Form.Label>
                <Form.Control
                  name="sGradUni"
                  value={sGradUni}
                  placeholder=" Student's University"
                  onChange={(e) => setsGradUni(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Graduation Year</Form.Label>
                <Form.Control
                  name="sGradYear"
                  value={sGradYear}
                  placeholder="Graduation Year"
                  onChange={(e) => setsGradYear(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Graduation Marks</Form.Label>
                <Form.Control
                  name="sGradMarks"
                  value={sGradMarks}
                  placeholder="Graduation Marks"
                  onChange={(e) => setsGradMarks(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Graduation Grade</Form.Label>
                <Form.Control
                  name="sGradGrade"
                  value={sGradGrade}
                  placeholder="Graduation Grade"
                  onChange={(e) => setsGradGrade(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Student's Post Graduation College</Form.Label>
                <Form.Control
                  name="sPostGradCollege"
                  value={sPostGradCollege}
                  placeholder="Student's GradCollege"
                  onChange={(e) => setsPostGradCollege(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={6} xs={12}>
              <Form.Group>
                <Form.Label>Student's Post Graduation University</Form.Label>
                <Form.Control
                  name="sPostGradUni"
                  value={sPostGradUni}
                  placeholder=" Student's University"
                  onChange={(e) => setsPostGradUni(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Post Graduation Year</Form.Label>
                <Form.Control
                  name="sPostGradYear"
                  value={sPostGradYear}
                  placeholder="Grad Year"
                  onChange={(e) => setsPostGradYear(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Post Graduation Marks</Form.Label>
                <Form.Control
                  name="sPostGradMarks"
                  value={sPostGradMarks}
                  placeholder="Post Graduation Marks"
                  onChange={(e) => setsPostGradMarks(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Post Graduation Grade</Form.Label>
                <Form.Control
                  name="sPostGradGrade"
                  value={sPostGradGrade}
                  placeholder="Post Graduation Grade"
                  onChange={(e) => setsPostGradGrade(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Student's Course Name</Form.Label>
                <Form.Control
                  name="sCourseName"
                  value={sCourseName}
                  placeholder="Student's Course Name"
                  onChange={(e) => setsCourseName(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Student's Course Country</Form.Label>
                <Form.Control
                  name="sCourseCountry"
                  value={sCourseCountry}
                  placeholder="Student's Course Country"
                  onChange={(e) => setsCourseCountry(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Group>
                <Form.Label>Student's Course Intake</Form.Label>
                <Form.Control
                  name="sCourseIntake"
                  value={sCourseIntake}
                  placeholder="Student's Course Intake"
                  onChange={(e) => setsCourseIntake(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          {submitting ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Submitting Please Wait</Tooltip>
              }
            >
              <Button
                style={{ marginTop: "30px" }}
                className="btn--outline--dark"
                disabled
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Submiting
              </Button>
            </OverlayTrigger>
          ) : (
            <Button
              style={{ marginTop: "30px" }}
              className="btn--outline--dark"
              onClick={(e) => FormSubmit(e)}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Submit
            </Button>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default VidyaForm;
