import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";

import AashrayLogo from "../images/aashray.jpg";
import AskJSGLogo from "../images/AskJSG.jpg";
import ConstitutionLogo from "../images/Constitution.jpg";
import EminenceLogo from "../images/Eminence.jpg";
import GurukulLogo from "../images/Gurukul.jpg";
import JSGIFLogo from "../images/logo.png";
import PariLogo from "../images/Pari.jpg";
import RunbhoomiLogo from "../images/Runbhoomi.jpg";
import SanginiLogo from "../images/Sangini.jpg";
import ShrotLogo from "../images/Shrot.jpg";
import UdaanLogo from "../images/Udaan.jpg";
import AakaarLogo from "../images/Aakaar.jpg";
import AhaarLogo from "../images/Ahaar.jpg";
import AnukampaLogo from "../images/Anukampa.jpg";
import KutumbLogo from "../images/Kutumb.jpg";
import NipunLogo from "../images/Nipun.jpg";
import PanaahLogo from "../images/Panaah.jpg";
import VidyaLogo from "../images/Vidya.jpg";

import Aashray from "../images/Aashray.zip";
import AskJSG from "../images/Ask JSG.zip";
import Constitution from "../images/Constitution&Bylaws.zip";
import Eminence from "../images/Eminence.zip";
import Gurukul from "../images/Gurukul.zip";
import JSGIF from "../images/JSGIF.zip";
import Pari from "../images/Pari.zip";
import Runbhoomi from "../images/Runbhoomi.zip";
import Sangini from "../images/Sangini.zip";
import Shrot from "../images/Shrot.zip";
import Udaan from "../images/Udaan.zip";
import Aakaar from "../images/Aakaar.zip";
import Ahaar from "../images/Ahaar.zip";
import Anukampa from "../images/Anukampa.zip";
import Kutumb from "../images/Kutumb.zip";
import Nipun from "../images/Nipun.zip";
import Panaah from "../images/Panaah.zip";

import "./LogoDownloads.css";
import { saveAs } from "file-saver";

function LogoDownloads() {
  function downloader(file, filename) {
    saveAs(file, filename);
  }

  return (
    <div>
      <Row>
        <h1>Logo Downloads</h1>
      </Row>
      <Row>
        <h2 style={{ textAlign: "center" }}>Federation Logos</h2>
        <Col xs={12} md={6}>
          <div className="downloadCard">
            <Image src={JSGIFLogo} height="160px" />
            <h3>JSGIF</h3>
            <Button
              className="btn--secondary"
              style={{ width: "100%", marginTop: "10px" }}
              onClick={() => downloader(JSGIF, "JSGIF Logo")}
            >
              Download
            </Button>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="downloadCard">
            <Image src={SanginiLogo} height="160px" />
            <h3>Sangini</h3>
            <Button
              className="btn--secondary"
              style={{ width: "100%", marginTop: "10px" }}
              onClick={() => downloader(Sangini, "Sangini Logo")}
            >
              Download
            </Button>
          </div>
        </Col>
      </Row>

      <Row style={{ marginTop: "30px" }}>
        <h2 style={{ textAlign: "center" }}>Venture Logos</h2>
        <Col xs={12} md={4} lg={3}>
          <div className="downloadCard">
            <div className="downloadImgContainer">
              <Image src={AashrayLogo} className="downloadImage" fluid />
            </div>
            <h3>Aashray</h3>
            <Button
              className="btn--secondary"
              style={{ width: "100%", marginTop: "10px" }}
              onClick={() => downloader(Aashray, "Aashray Logo")}
            >
              Download
            </Button>
          </div>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <div className="downloadCard">
            <div className="downloadImgContainer">
              <Image src={AakaarLogo} className="downloadImage" fluid />
            </div>
            <h3>Aashray आkaar</h3>
            <Button
              className="btn--secondary"
              style={{ width: "100%", marginTop: "10px" }}
              onClick={() => downloader(Aakaar, "आkaar Logo")}
            >
              Download
            </Button>
          </div>
        </Col>

        <Col xs={12} md={4} lg={3}>
          <div className="downloadCard">
            <div className="downloadImgContainer">
              <Image src={AhaarLogo} className="downloadImage" fluid />
            </div>
            <h3>Aashray आhaar</h3>
            <Button
              className="btn--secondary"
              style={{ width: "100%", marginTop: "10px" }}
              onClick={() => downloader(Ahaar, "आhaar Logo")}
            >
              Download
            </Button>
          </div>
        </Col>

        <Col xs={12} md={4} lg={3}>
          <div className="downloadCard">
            <div className="downloadImgContainer">
              <Image src={AnukampaLogo} className="downloadImage" fluid />
            </div>
            <h3>Aashray Anuकंपा</h3>
            <Button
              className="btn--secondary"
              style={{ width: "100%", marginTop: "10px" }}
              onClick={() => downloader(Anukampa, "Anuकंपा Logo")}
            >
              Download
            </Button>
          </div>
        </Col>

        <Col xs={12} md={4} lg={3}>
          <div className="downloadCard">
            <div className="downloadImgContainer">
              <Image src={PanaahLogo} className="downloadImage" fluid />
            </div>
            <h3>Aashray Panaah</h3>
            <Button
              className="btn--secondary"
              style={{ width: "100%", marginTop: "10px" }}
              onClick={() => downloader(Panaah, "Panaah Logo")}
            >
              Download
            </Button>
          </div>
        </Col>

        <Col xs={12} md={4} lg={3}>
          <div className="downloadCard">
            <div className="downloadImgContainer">
              <Image src={AskJSGLogo} className="downloadImage" fluid />
            </div>
            <h3>Ask JSG</h3>
            <Button
              className="btn--secondary"
              style={{ width: "100%", marginTop: "10px" }}
              onClick={() => downloader(AskJSG, "Ask JSG Logo")}
            >
              Download
            </Button>
          </div>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <div className="downloadCard">
            <div className="downloadImgContainer">
              <Image src={ConstitutionLogo} className="downloadImage" fluid />
            </div>
            <h3 style={{ letterSpacing: "-1px" }}>Constitution & Bylaws</h3>
            <Button
              className="btn--secondary"
              style={{ width: "100%", marginTop: "10px" }}
              onClick={() =>
                downloader(Constitution, "Constitution & Bylaws Logo")
              }
            >
              Download
            </Button>
          </div>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <div className="downloadCard">
            <div className="downloadImgContainer">
              <Image src={EminenceLogo} className="downloadImage" fluid />
            </div>
            <h3>Eminence</h3>
            <Button
              className="btn--secondary"
              style={{ width: "100%", marginTop: "10px" }}
              onClick={() => downloader(Eminence, "Eminence Logo")}
            >
              Download
            </Button>
          </div>
        </Col>

        <Col xs={12} md={4} lg={3}>
          <div className="downloadCard">
            <div className="downloadImgContainer">
              <Image src={GurukulLogo} className="downloadImage" fluid />
            </div>
            <h3>Gurukul</h3>
            <Button
              className="btn--secondary"
              style={{ width: "100%", marginTop: "10px" }}
              onClick={() => downloader(Gurukul, "Gurukul Logo")}
            >
              Download
            </Button>
          </div>
        </Col>

        <Col xs={12} md={4} lg={3}>
          <div className="downloadCard">
            <div className="downloadImgContainer">
              <Image src={KutumbLogo} className="downloadImage" fluid />
            </div>
            <h3>कुtumb</h3>
            <Button
              className="btn--secondary"
              style={{ width: "100%", marginTop: "10px" }}
              onClick={() => downloader(Kutumb, "Kutumb Logo")}
            >
              Download
            </Button>
          </div>
        </Col>

        <Col xs={12} md={4} lg={3}>
          <div className="downloadCard">
            <div className="downloadImgContainer">
              <Image src={PariLogo} className="downloadImage" fluid />
            </div>
            <h3>Pari</h3>
            <Button
              className="btn--secondary"
              style={{ width: "100%", marginTop: "10px" }}
              onClick={() => downloader(Pari, "Pari Logo")}
            >
              Download
            </Button>
          </div>
        </Col>
        <Col xs={12} md={4} lg={3}>
          <div className="downloadCard">
            <div className="downloadImgContainer">
              <Image src={RunbhoomiLogo} className="downloadImage" fluid />
            </div>
            <h3 style={{ letterSpacing: "-1px" }}>Runbhoomi</h3>
            <Button
              className="btn--secondary"
              style={{ width: "100%", marginTop: "10px" }}
              onClick={() => downloader(Runbhoomi, "Runbhoomi Logo")}
            >
              Download
            </Button>
          </div>
        </Col>

        <Col xs={12} md={4} lg={3}>
          <div className="downloadCard">
            <div className="downloadImgContainer">
              <Image src={ShrotLogo} className="downloadImage" fluid />
            </div>
            <h3>Shrot</h3>
            <Button
              className="btn--secondary"
              style={{ width: "100%", marginTop: "10px" }}
              onClick={() => downloader(Shrot, "Shrot Logo")}
            >
              Download
            </Button>
          </div>
        </Col>

        <Col xs={12} md={4} lg={3}>
          <div className="downloadCard">
            <div className="downloadImgContainer">
              <Image src={UdaanLogo} className="downloadImage" fluid />
            </div>
            <h3>Udaan</h3>
            <Button
              className="btn--secondary"
              style={{ width: "100%", marginTop: "10px" }}
              onClick={() => downloader(Udaan, "Udaan Logo")}
            >
              Download
            </Button>
          </div>
        </Col>

        <Col xs={12} md={4} lg={3}>
          <div className="downloadCard">
            <div className="downloadImgContainer">
              <Image src={NipunLogo} className="downloadImage" fluid />
            </div>
            <h3>Udaan निpun</h3>
            <Button
              className="btn--secondary"
              style={{ width: "100%", marginTop: "10px" }}
              onClick={() => downloader(Nipun, "निpun Logo")}
            >
              Download
            </Button>
          </div>
        </Col>

        <Col xs={12} md={4} lg={3}>
          <div className="downloadCard">
            <div className="downloadImgContainer">
              <Image src={VidyaLogo} className="downloadImage" fluid />
            </div>
            <h3>JSGIF Vidya</h3>
            <Button
              className="btn--secondary"
              style={{ width: "100%", marginTop: "10px" }}
              onClick={() => downloader(VidyaLogo, "Vidya Logo")}
            >
              Download
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default LogoDownloads;
