import React, { useState, useRef, useEffect } from "react";
import { Card, Row, Col, Image, Button, Accordion } from "react-bootstrap";
import logo from "../images/Vidya.jpg";
import "./ventures.css";
import { useNavigate } from "react-router-dom";
import HTMLFlipBook from "react-pageflip";
import axios from "axios";
import { saveAs } from "file-saver";
import pdfFile from "../images/Vidya-SOP.pdf";
import pdfFile2 from "../images/Vidya-APP.pdf";
import { LinkContainer } from "react-router-bootstrap";
import Images from "../images";

function Vidya() {
  const navigate = useNavigate();

  const zoomBgRedirect = () => {
    navigate(`/fed/zoom-background/`);
  };

  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState("");

  const [bookWidth, setBookWidth] = useState("");
  const [bookHeight, setBookHeight] = useState("");

  const [isPageFlipReady, setIsPageFlipReady] = useState(false);

  const pageFlipRef = useRef();

  const handlePageChange = () => {
    let page = pageFlipRef.current.pageFlip().getCurrentPageIndex();
    setCurrentPage(page);
    console.log(page);
  };

  useEffect(() => {
    if (isPageFlipReady) {
      handlePageChange();
    }
  }, [isPageFlipReady]);

  const prevPage = () => {
    pageFlipRef.current.pageFlip().flipPrev();
  };

  const nextPage = () => {
    pageFlipRef.current.pageFlip().flipNext();
  };

  useEffect(() => {
    const totalPageCounter = () => {
      let counter = pageFlipRef.current.pageFlip().getPageCount();
      setTotalPages(counter);
    };

    if (isPageFlipReady) {
      totalPageCounter();
    }
  }, [isPageFlipReady]);

  const fetchPages = async () => {
    const pageResponse = await axios.get(`/api/book/43`);
    console.log(pageResponse.data);
    setPages(pageResponse.data);
  };

  useEffect(() => {
    fetchPages();
  }, []);

  const downloadPDF = () => {
    saveAs(pdfFile, "Vidya SOP.pdf");
  };

  const downloadPDF2 = () => {
    saveAs(pdfFile2, "Vidya Application Process Guidelines.pdf");
  };

  const [turnEnable, setTurnEnable] = useState(true);

  useEffect(() => {
    function handleResize() {
      const isMobile = window.innerWidth < 768; // or any other breakpoint you prefer
      setTurnEnable(!isMobile);
    }
    handleResize(); // Call handleResize initially to set the initial value of turnEnable
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const divRef = useRef(null);

  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleFullscreen = () => {
    const element = divRef.current;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  const handleExitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  useEffect(() => {
    function handleContainer() {
      if (window.innerWidth > 1400) {
        setBookWidth(isFullscreen ? "1400px" : "1400px");
        setBookHeight(isFullscreen ? "1060px" : "100%");
      } else {
        setBookWidth("100%");
        setBookHeight("100%");
      }
    }
    handleContainer();
  }, [window.innerWidth, isFullscreen]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(
        document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement
      );
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  }, []);

  const [committees, setCommittees] = useState([]);
  const [specialCommittees, setSpecialCommittees] = useState([]);
  const [advisors, setadvisors] = useState([]);

  const fetchCommittee = async () => {
    const response = await axios.get(`/api/data/venture-committee/vidya`);

    const sortedData = response.data.sort((a, b) => a.order - b.order);
    const special = sortedData.filter(
      (committee) => committee.order === "1" || committee.order === "2"
    );
    const special2 = sortedData.filter(
      (committee) =>
        committee.order === "21" ||
        committee.order === "22" ||
        committee.order === "23" ||
        committee.order === "24" ||
        committee.order === "25" ||
        committee.order === "26" ||
        committee.order === "27"
    );

    setadvisors(special2);
    setSpecialCommittees(special);
    setCommittees(
      sortedData.filter(
        (committee) =>
          committee.order !== "1" &&
          committee.order !== "2" &&
          committee.order !== "21" &&
          committee.order !== "22" &&
          committee.order !== "23" &&
          committee.order !== "24" &&
          committee.order !== "25" &&
          committee.order !== "25" &&
          committee.order !== "27"
      )
    );
  };

  useEffect(() => {
    fetchCommittee();
  }, []);

  return (
    <div>
      <div className="ventureContainer">
        <Row>
          <Col md={3} xs={6}>
            <Image src={logo} fluid className="logoImg" />
          </Col>
          <Col
            md={6}
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1 style={{ letterSpacing: "-2.5px" }}>VIDYA</h1>
          </Col>
          <Col md={3} xs={6}></Col>
        </Row>
      </div>
      <div
        className="ventureContainer"
        style={{ marginTop: "10px", paddingTop: "10px" }}
      >
        <Row></Row>
        <Row>
          {specialCommittees.map((committeeChairman) => (
            <Col
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
              sm="6"
              md="6"
              lg="6"
            >
              <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
                Committee {committeeChairman.post}
              </h2>

              <div className="flip2">
                <Card className="front2">
                  <Row
                    style={{
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                  >
                    <Card.Img
                      className="cardImage1"
                      src={committeeChairman.photo}
                    ></Card.Img>
                  </Row>
                  <Row>
                    <h2 className="userName">
                      JSGian {committeeChairman.name}
                    </h2>
                  </Row>
                </Card>
                <Card className="back2">
                  <h2 className="userName1" style={{ marginBottom: "10px" }}>
                    JSGian {committeeChairman.name}
                  </h2>
                  <h3
                    style={{ fontSize: "18px", margin: "0" }}
                    className="userName1"
                  >
                    {committeeChairman.post}
                  </h3>
                  <p style={{ fontSize: "14px" }} className="userInfoEmail">
                    <span className="infoHead">Email : </span>{" "}
                    {committeeChairman.email}
                  </p>
                  <p style={{ fontSize: "14px" }} className="userInfo1">
                    <span className="infoHead">Contact Number : </span>
                    {committeeChairman.mobile}
                  </p>
                </Card>
              </div>
            </Col>
          ))}
          <Row>
            <h2 style={{ textAlign: "center" }}>Advisors</h2>
            {advisors.map((user) => (
              <Col
                style={{
                  margin: "1em 0em",
                  display: "flex",
                  justifyContent: "center",
                }}
                sm="6"
                md="4"
                lg="4"
                key={user.id}
              >
                <div className="flip3">
                  <Card className="front3">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img
                        className="cardImage2"
                        src={user.photo}
                      ></Card.Img>
                    </Row>
                    <Row>
                      <h2 className="userName">JSGian {user.name}</h2>
                    </Row>
                  </Card>
                  <Card className="back3">
                    <h2
                      className="userName1"
                      style={{ fontSize: "18px", marginBottom: "0px" }}
                    >
                      JSGian {user.name}
                    </h2>
                    <h3
                      style={{ fontSize: "14px", margin: "0" }}
                      className="userName1"
                    >
                      {user.post}
                    </h3>
                    <p
                      style={{
                        fontSize: "12px",
                        margin: "0",
                        marginTop: "5px",
                      }}
                      className="userInfoEmail"
                    >
                      <span className="infoHead">Email : </span> {user.email}
                    </p>
                    <p
                      style={{ fontSize: "12px", margin: "0" }}
                      className="userInfo1"
                    >
                      <span className="infoHead">Contact Number : </span>
                      {user.mobile}
                    </p>
                  </Card>
                </div>
              </Col>
            ))}
          </Row>
          <h2 style={{ textAlign: "center" }}>Committee Members</h2>
          {committees.map((user) => (
            <Col
              style={{
                margin: "1em 0em",
                display: "flex",
                justifyContent: "center",
              }}
              sm="6"
              md="3"
              lg="2"
              key={user.id}
            >
              <div className="flip3">
                <Card className="front3">
                  <Row
                    style={{
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                  >
                    <Card.Img
                      className="cardImage2"
                      src={user.photo}
                    ></Card.Img>
                  </Row>
                  <Row>
                    <h2 className="userName">JSGian {user.name}</h2>
                  </Row>
                </Card>
                <Card className="back3">
                  <h2
                    className="userName1"
                    style={{ fontSize: "18px", marginBottom: "0px" }}
                  >
                    JSGian {user.name}
                  </h2>
                  <h3
                    style={{ fontSize: "14px", margin: "0" }}
                    className="userName1"
                  >
                    {user.post}
                  </h3>
                  <p
                    style={{ fontSize: "12px", margin: "0", marginTop: "5px" }}
                    className="userInfoEmail"
                  >
                    <span className="infoHead">Email : </span> {user.email}
                  </p>
                  <p
                    style={{ fontSize: "12px", margin: "0" }}
                    className="userInfo1"
                  >
                    <span className="infoHead">Contact Number : </span>
                    {user.mobile}
                  </p>
                </Card>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <div
        className="ventureContainer"
        style={{ marginTop: "10px", paddingTop: "10px" }}
      >
        <Row>
          <Col md={6} xs={12} style={{ marginTop: "20px" }}>
            <Row
              style={{
                marginTop: "20px",
                textAlign: "center",
                padding: "0px 15px",
              }}
            >
              <Button onClick={zoomBgRedirect} className="btn--secondary">
                Get Zoom Background
              </Button>
            </Row>
          </Col>
          <Col md={6} xs={12} style={{ marginTop: "20px" }}>
            <Row
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                padding: "0px 15px",
              }}
            >
              <Button
                onClick={() => navigate("/form/vidya-application-form")}
                className="btn--secondary"
              >
                Application Form
              </Button>
            </Row>
          </Col>
          <Col md={6} xs={12} style={{ marginTop: "20px" }}>
            <Row
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                padding: "0px 15px",
              }}
            >
              <Button onClick={downloadPDF} className="btn--four">
                Download SOP in all Languages
              </Button>
            </Row>
          </Col>
          <Col md={6} xs={12} style={{ marginTop: "20px" }}>
            <Row
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                padding: "0px 15px",
              }}
            >
              <Button onClick={downloadPDF2} className="btn--four">
                Download Application Process in all Languages
              </Button>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col style={{ marginTop: "20px" }}>
            <Row
              ref={divRef}
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#face1d",
              }}
            >
              <div
                className="gurukul-book-container"
                style={{ width: bookWidth, height: bookHeight }}
              >
                <div>
                  <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        Standard Operating Procedure
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row style={{ marginTop: "5px" }}>
                          <Row>
                            <Col></Col>
                            <Col
                              xs={10}
                              style={{ textAlign: "center", margin: "10px 0" }}
                            >
                              <h4>Standard Operating Procedure - English</h4>
                              <Image
                                src={Images.VidyaSopE}
                                alt="Mangalyatra Special Edition"
                                className="bookCover"
                                fluid
                              />
                            </Col>
                            <Col></Col>
                          </Row>
                          <Row>
                            <Col></Col>
                            <Col
                              xs={10}
                              style={{ textAlign: "center", margin: "10px 0" }}
                            >
                              <h4>Standard Operating Procedure - Hindi</h4>
                              <Image
                                src={Images.VidyaSopH}
                                alt="Mangalyatra Special Edition"
                                className="bookCover"
                                fluid
                              />
                            </Col>
                            <Col></Col>
                          </Row>
                          <Row>
                            <Col></Col>
                            <Col
                              xs={10}
                              style={{ textAlign: "center", margin: "10px 0" }}
                            >
                              <h4>Standard Operating Procedure - Gujarati</h4>
                              <Image
                                src={Images.VidyaSopG}
                                alt="Mangalyatra Special Edition"
                                className="bookCover"
                                fluid
                              />
                            </Col>
                            <Col></Col>
                          </Row>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        Application Process Guidelines
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row style={{ marginTop: "5px" }}>
                          <Col style={{ textAlign: "center" }}>
                            <h4>Application Process Guidelines - English</h4>
                            <Image
                              src={Images.VidyaAppE}
                              alt="Mangalyatra 9 Cover Eng"
                              className="bookCover"
                              fluid
                            />
                          </Col>
                          <Col style={{ textAlign: "center" }}>
                            <h4>Application Process Guidelines - Hindi</h4>
                            <Image
                              src={Images.VidyaAppH}
                              alt="Mangalyatra 9 Cover Hin"
                              className="bookCover"
                              fluid
                            />
                          </Col>
                          <Col style={{ textAlign: "center" }}>
                            <h4>Application Process Guidelines - Gujarati</h4>
                            <Image
                              src={Images.VidyaAppG}
                              alt="Mangalyatra 9 Cover Guj"
                              className="bookCover"
                              fluid
                            />
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Vidya;
